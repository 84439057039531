<template>
    <div>
        <BreadcrumbNav :page-name="$t('accountManagement.editingMaterials')"></BreadcrumbNav>
        <div class="redact_box auto">
            <div class="avatar_box">
                <img :src="avatar" alt="" class="avatar fl">
                <div class="fl upload_avatar">
                    <el-upload
                            :action="actionUrl"
                            :auto-upload="false"
                            :before-upload="handleBefore"
                            :data="uploadParams"
                            :on-change="handleChange"
                            :on-error="handleError"
                            :on-success="handleSuccess"
                            :show-file-list="false"
                            class="upload-avatar"
                            list-type="picture"
                            ref="upload"
                    >
                        <el-button size="small" style="color: black" type="primary">{{
                            $t("accountManagement.selectFile") }}
                        </el-button>
                    </el-upload>
                    <span>{{ avatarName }}</span>
                    <p>JPG or PNG.Max size of 800K</p>
                    <div @click="uploadNow" class="cur avatar_btn">
                        Upload Now
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="input_item">
                <div class="input_item_title">
                    <span>*</span>{{ $t("accountManagement.fullName") }}
                </div>
                <div class="input_box">
                    <input :placeholder="$t('publish.input')" type="text" v-model="name">
                </div>
            </div>
            <div class="choose_sex">
                <div class="input_item_title">
                    <span>*</span>{{ $t("accountManagement.gender") }}
                </div>
                <div class="sex_box">
                    <div @click="sexId = 'male'" class="sex_item fl">
                        <img alt="" class="cur" height="16px" src="../../assets/images/radio_s@2x.png"
                             v-if="sexId == 'male'" width="16">
                        <img alt="" class="cur" height="16px" src="../../assets/images/radio_n@2x.png" v-else
                             width="16">
                        {{ $t("accountManagement.male") }}
                    </div>
                    <div @click="sexId = 'female'" class="sex_item fl">
                        <img alt="" class="cur" height="16px" src="../../assets/images/radio_s@2x.png"
                             v-if="sexId == 'female'" width="16">
                        <img alt="" class="cur" height="16px" src="../../assets/images/radio_n@2x.png" v-else
                             width="16">
                        {{ $t("accountManagement.female") }}
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div class="input_item">
                <div class="input_item_title">
                    <span>*</span>{{ $t("accountManagement.mobile") }}
                </div>
                <div>
                    <div>
                        <div class="area-code" style="margin-top: 15px;width: 536px">
                            <el-input :placeholder="$t('publish.input')" class="input-with-select" v-model="mobile">
                                <el-select :placeholder="$t('message.pleaseSelect')/*请选择*/" filterable slot="prepend"
                                           v-model="mobileCode">
                                    <el-option :key="item.id"
                                               :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)"
                                               :value="item.code"
                                               v-for="item in areaList.phoneCountriesCodeList"></el-option>
                                </el-select>
                            </el-input>
                        </div>
                        <!--<span style="color:red;position: absolute;margin-top:10px;" v-show="show9">手机号不能为空</span>-->
                    </div>
                </div>
            </div>
            <div class="input_item">
                <div class="input_item_title">
                    <span>*</span>{{ $t("accountManagement.email") }}
                </div>
                <div class="input_box">
                    <input :placeholder="$t('publish.input')" type="text" v-model="mail">
                </div>
            </div>
            <div class="input_item">
                <div class="input_item_title">
                    <span>*</span>WhatsApp
                </div>
                <div class="area-code" style="margin-top: 15px;width: 536px">
                    <el-input :placeholder="$t('publish.input')" class="input-with-select" v-model="whatsApp">
                        <el-select :placeholder="$t('message.pleaseSelect')/*请选择*/" filterable slot="prepend"
                                   v-model="whatsappCode">
                            <el-option :key="item.id"
                                       :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)"
                                       :value="item.code"
                                       v-for="item in areaList.phoneCountriesCodeList"></el-option>
                        </el-select>
                    </el-input>
                </div>
            </div>
            <div class="input_item company-input">
                <div class="input_item_title">
                    <span>*</span>{{ $t("accountManagement.companyName") }}
                </div>
                <div class="input_box" style="z-index: 999">
                    <img :src="companyState.logo" alt=""
                         style="width: 36px;height: 36px;border-radius: 4px;overflow: hidden" v-if="true">
                    <el-autocomplete
                            :fetch-suggestions="querySearchAsync"
                            :placeholder="$t('memberClassification.enterCompanyName')"
                            @select="handleSelect"
                            v-model="companyState.value"
                    ></el-autocomplete>
                </div>
            </div>
            <div class="input_item">
                <div class="input_item_title">
                    <span>*</span>{{ $t("accountManagement.officialWebsiteAddress") }}
                </div>
                <div class="input_box">
                    <input :placeholder="$t('publish.input')" type="text" v-model="website">
                </div>
            </div>
            <div class="input_item">
                <div class="input_item_title">
                    <span>*</span>{{ $t("accountManagement.professionalTitle") }}
                </div>
                <div class="input_box">
                    <el-autocomplete
                            :fetch-suggestions="positionSearchAsync"
                            :placeholder="$t('publish.input')"
                            @select="positionHandleSelect"
                            v-model="position"
                    ></el-autocomplete>
                </div>
            </div>
            <!--语音介绍-->
            <div class="input_item">
                <div class="input_item_title">
                    <span>*</span>{{ $t("accountManagement.voiceIntroduction") }}
                </div>
                <div class="box-area clearfix1">
                    <el-upload
                            :action="actionUrl1"
                            :before-upload="beforeMp3Upload"
                            :on-on-error="handleMp3Error"
                            :on-success="handleMp3Success"
                            :show-file-list="false"
                            class="upload-demo"
                    >
                        <div class="media-box">+{{ $t("accountManagement.voiceIntroduction") }}</div>
                    </el-upload>
                    <audio :src="audio" controls="controls" v-if="audio">
                        Your browser does not support the audio element.
                    </audio>
                </div>
            </div>
            <!--视频音频价格-->
            <div class="input_item" style="margin-bottom: 47px;">
                <div class="input_item_title">
                    <span>*</span>{{ $t("accountManagement.priceOf3MinutesCommunication") }}
                </div>
                <ul class="three_price">
                    <li class="talk">
                        <span>{{ $t("personalCenter.videoCommunication") }}</span>
                        <img alt="" src="@/assets/images/icon_right@2x.png"/>
                    </li>
                    <li>
                        <input :placeholder="$t('accountManagement.lowestPrice') + '50$'" type="text"
                               v-model="exchangePriceVideo">
                        <span>$</span>
                    </li>
                    <div class="clearfix"></div>
                    <li class="talk">
                        <span>{{ $t("personalCenter.audioCommunication") }}</span>
                        <img alt="" src="@/assets/images/icon_right@2x.png"/>
                    </li>
                    <li>
                        <input :placeholder="$t('accountManagement.lowestPrice') + '20$'" type="text"
                               v-model="exchangePriceAudio">
                        <span>$</span>
                    </li>
                    <div class="clearfix"></div>
                </ul>
            </div>
            <div class="rich_box">
                <p style="font-size: 18px; font-weight: bold; color: #23241f"><span>*</span>my blog</p>
                <div class="rich_con">
                    <!--<editor :api-key="apikey" :init="editorInit" v-model="editContent" />-->
                    <quill-editor
                            :options="editorOption"
                            @blur="onEditorBlur($event)"
                            @focus="onEditorFocus($event)"
                            @ready="onEditorReady($event)"
                            ref="myQuillEditor"
                            v-model="richContent"
                    />
                </div>
            </div>
            <!--附件-->
            <div class="input_item">
                <div class="input_item_title">
                    <span></span>{{ $t("accountManagement.uploadEnclosure") }}
                </div>
                <div class="box-area clearfix1">
                    <el-upload
                            :action="actionUrl2"
                            :before-upload="beforeEnclosureUpload"
                            :on-on-error="handleEnclosureError"
                            :on-success="handleEnclosureSuccess"
                            :show-file-list="false"
                            class="upload-demo"
                    >
                        <div class="media-box">+{{ $t("accountManagement.uploadEnclosure") }}</div>
                    </el-upload>
                    <div class="media-box" v-if="pdfName">
                        <span @click="goFile" class="cur">{{ pdfName }}</span>
                        <img @click="enclosure='',pdfName=''" alt="" src="@/assets/images/closed@2x.png">
                    </div>
                </div>
            </div>
            <div class="input_list">
                <div class="input_item">

                    <div class="input_item_title">
                        <p class="fl">{{ $t("accountManagement.wikipediaLink") }}</p>
                        <div class="del_btn fr">
                            <img @click="delLink(index)" alt="" src="../../assets/images/icon_del@2x.png" width="20px">
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="input_box" style="margin-left: 0">
                        <input :placeholder="$t('accountManagement.title')" type="text"
                               v-model="wikiLinkList.linkTitle">
                    </div>
                    <div class="input_box" style="margin-left: 0">
                        <input :placeholder="$t('accountManagement.pleasePasteTheLink')" type="text"
                               v-model="wikiLinkList.linkUrl">
                    </div>
                </div>
                <div>
                    <div :key="index" class="input_item" v-for="(item,index) in youtubeLinkList">
                        <div class="input_item_title">
                            <p class="fl">Youtube{{ $t("accountManagement.link") }}</p>
                            <div class="del_btn fr">
                                <img @click="delLink(index,'2')" alt="" src="../../assets/images/icon_del@2x.png"
                                     width="20px">
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="input_box" style="margin-left: 0">
                            <input :placeholder="$t('accountManagement.title')" type="text" v-model="item.linkTitle">
                        </div>
                        <div class="input_box" style="margin-left: 0">
                            <input :placeholder="$t('accountManagement.pleasePasteTheLink')" type="text"
                                   v-model="item.linkUrl">
                        </div>
                    </div>
                    <div @click="addLink('2')" class="add_more cur">
                        +{{ $t("accountManagement.addMore") }}
                    </div>
                </div>

                <div>
                    <div :key="index+1" class="input_item" v-for="(item,index) in newsLinkList">
                        <div class="input_item_title">
                            <p class="fl">News{{ $t("accountManagement.link") }}</p>
                            <div class="del_btn fr">
                                <img @click="delLink(index,'3')" alt="" src="../../assets/images/icon_del@2x.png"
                                     width="20px">
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="input_box" style="margin-left: 0">
                            <input :placeholder="$t('accountManagement.title')" type="text" v-model="item.linkTitle">
                        </div>
                        <div class="input_box" style="margin-left: 0">
                            <input :placeholder="$t('accountManagement.pleasePasteTheLink')" type="text"
                                   v-model="item.linkUrl">
                        </div>
                    </div>
                    <div @click="addLink('3')" class="add_more cur">
                        +{{ $t("accountManagement.addMore") }}
                    </div>
                </div>
            </div>

            <!--要求-->
            <div class="requireBox">
                <div class="input_item_title">
                    <span></span>{{ $t("accountManagement.privacyRequirements") }}
                </div>
                <ul class="require_item">
                    <li>
                        <img alt="" src="../../assets/images/radio_s@2x.png" v-if="requireId == '1'" width="16px">
                        <img @click="requireId='1'" alt="" class="cur" src="../../assets/images/radio_n@2x.png" v-else
                             width="16px">
                        {{ $t("accountManagement.welcomeToContact") }}
                    </li>
                    <li>
                        <img alt="" src="../../assets/images/radio_s@2x.png" v-if="requireId == '2'" width="16px">
                        <img @click="requireId='2'" alt="" class="cur" src="../../assets/images/radio_n@2x.png" v-else
                             width="16px">
                        {{ $t("accountManagement.refuseAllContact") }}
                    </li>
                    <li>
                        <img alt="" src="../../assets/images/radio_s@2x.png" v-if="requireId == '3'" width="16px">
                        <img @click="requireId='3'" alt="" class="cur" src="../../assets/images/radio_n@2x.png" v-else
                             width="16px">
                        {{ $t("accountManagement.pleaseContactMeInTheFollowingTimePeriod") }}
                    </li>
                </ul>
                <div class="choose_time fl">
                    <el-date-picker
                            :placeholder="$t('myInterviewDetail.optionDate')"
                            @focus="requireId='3'"
                            class="choose_time_a"
                            type="date"
                            v-model="requireBeginTime">
                    </el-date-picker>
                    <img alt="" class="fr" src="../../assets/images/icon_date@2x.png">
                </div>
                <div class="fl choose_time_a_line">-</div>
                <div class="choose_time fl">
                    <el-date-picker
                            :placeholder="$t('myInterviewDetail.optionDate')"
                            @focus="requireId='3'"
                            class="choose_time_a"
                            type="date"
                            v-model="requireEndTime">
                    </el-date-picker>
                    <img alt="" class="fr" src="../../assets/images/icon_date@2x.png">
                </div>
                <div class="clearfix"></div>
                <div @click="submitMsg" class="save_btn cur">
                    SAVE
                </div>
            </div>
        </div>
        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import RichText from "@/components/richText";
    import {editTalentInfo, findTalentInfo, selectCountriesCodeInfo} from '@/api/userApi'
    import {findCompanyList, findPostFunctionList} from "@/api/auxiliaryInterfaceApi";
    import Loading from "@/components/loading";
    import {uploadAvatarOrBackdrop, uploadingMp3, uploadingEnclosure} from "@/api/uploadApi"
    import {Loading as elLoading} from "element-ui";
    import {tinyMixin} from "@/mixins/tinymce";
    import {quillMixin} from "@/mixins/quillEdit";


    let loadingInstance = null;
    export default {
        name: "redact",
        mixins: [tinyMixin, quillMixin],
        data() {
            return {
                //上传文件携带参数
                uploadParams: {
                    type: 1
                },
                actionUrl1: uploadingMp3,
                actionUrl2: uploadingEnclosure,
                actionUrl: uploadAvatarOrBackdrop,
                isLoading: false,
                companyState: {
                    id: '',
                    value: '',
                    logo: '',
                    address: '',
                    regions: '',
                },
                areaList: [],
                sexId: '1',
                requireId: '1',
                avatar: '',//头像
                avatarUrl: '',//头像最终地址
                avatarName: this.$t("accountManagement.noFilesSelected"),
                file: '',
                name: '',
                mobileCodes: '',
                mobileCode: '86',
                whatsappCodes: '',
                whatsappCode: '86',
                mobile: "",
                mail: '',
                whatsApp: '',
                companyName: '',
                companyLogo: '',
                website: '',
                position: '',
                exchangePriceAudio: '',
                exchangePriceVideo: '',
                requireBeginTime: '',
                requireEndTime: '',
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                },],
                //维基百科
                wikiLinkList: {
                    linkType: '1',
                    linkTitle: '',
                    linkUrl: '',
                },
                //油管
                youtubeLinkList: [
                    {
                        linkType: '2',
                        linkTitle: '',
                        linkUrl: '',
                    },
                ],
                //news
                newsLinkList: [
                    {
                        linkType: '3',
                        linkTitle: '',
                        linkUrl: '',
                    },
                ],
                editorContent: '',
                audio: '',
                enclosure: '',
                pdfName: '',
            }
        },
        computed: {
            linkList() {
                let list = [];
                if (this.wikiLinkList.linkTitle !== '' && this.wikiLinkList.linkUrl !== '') {
                    list.push(this.wikiLinkList);
                }
                this.youtubeLinkList.forEach((e1) => {
                    if (e1.linkTitle !== '' && e1.linkUrl !== '') {
                        list.push(e1);
                    }
                });
                this.newsLinkList.forEach((e2) => {
                    if (e2.linkTitle !== '' && e2.linkUrl !== '') {
                        list.push(e2);
                    }
                });
                // return list.toString();
                return JSON.stringify(list);
            }

        },
        components: {
            BreadcrumbNav,
            RichText, Loading
        },
        mounted() {
            //获取地区编号
            this.getArea();
            this.getUserMsg();
        },
        methods: {
            selectChangeHandle(val) {
                console.log(val)
            },
            formatCode(code, en, cn) {
                if (this.$i18n.locale == 'zh') {
                    return "+" + code + "(" + cn + ")";
                } else {
                    return "+" + code + "(" + en + ")";

                }
            },
            goFile() {
                window.open(this.enclosure)
            },
            //音频上传相关
            beforeMp3Upload(file) {
                const isMP3 = file.type == "audio/mpeg" || file.type == "audio/x-m4a";
                const isLt2M = file.size / 1024 / 1024 < 20;
                console.log(file.type)

                if (!isMP3) {
                    this.$message.error(this.$i18n.locale == 'zh' ? '上传音频只能是 MP3/m4a 格式' : 'Uploaded audio can only be in MP3 / m4a format!');//上传audio只能是 MP3/m4a 格式
                    console.log(file.type)
                    // loadingInstance.close();

                }
                if (!isLt2M) {

                    this.$message.error(this.$i18n.locale == 'zh' ? '上传音频大小不能超过 20MB' : 'The size of the uploaded audio cannot exceed 20MB!');//上传audio大小不能超过 20MB
                    // loadingInstance.close();

                }
                if (isMP3 && isLt2M) {
                    loadingInstance = elLoading.service({
                        text: this.$i18n.locale == 'zh' ? '拼命上传中' : 'Desperately uploading',//拼命上传中
                        // spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                }

                return isMP3 && isLt2M;
            },
            handleMp3Success(res, file) {
                loadingInstance.close();
                if (res.code == 200) {
                    this.audio = res.data.path;
                }

            },
            handleMp3Error(err, file, fileList) {
                loadingInstance.close();
                this.$message.error(err)
            },
            //附件上传相关
            beforeEnclosureUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 20;

                if (!isLt2M) {
                    this.$message.error('The size of the uploaded avatar image cannot exceed 20MB!');//上传头像图片大小不能超过 20MB
                    loadingInstance.close();

                }
                loadingInstance = elLoading.service({
                    text: 'Desperately uploading',//拼命上传中
                    // spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                return isLt2M;
            },
            handleEnclosureSuccess(res, file) {
                console.log(res, file)
                loadingInstance.close();
                if (res.code == 200) {
                    this.enclosure = res.data.path;
                    this.pdfName = file.name;
                } else {
                    this.$message.warning(res.msg)
                }

            },
            handleEnclosureError(err, file, fileList) {
                loadingInstance.close();
                this.$message.error(err)
            },
            changemobileCodes() {
                if (this.mobileCode == null || this.mobileCode.length == 0) {
                    this.mobileCodes = "";
                } else {
                    this.mobileCodes = "+" + this.mobileCode;
                }
            },
            changewhatsappCodes() {
                if (this.whatsappCode == null || this.whatsappCode.length == 0) {
                    this.whatsappCodes = "";
                } else {
                    this.whatsappCodes = "+" + this.whatsappCode;
                }
            },
            handleChange(file, fileList) {
                this.avatar = file.url;
                this.avatarName = file.name;
                if (fileList.length > 1) {
                    this.$refs.upload.uploadFiles.splice(0, 1)
                }
            },
            handleSuccess(response, file, fileList) {
                loadingInstance.close();
                if (response.code === 200) {
                    this.$message({
                        message: this.$t('user.ploadSucceeded'),
                        type: 'success'
                    });
                    this.avatarUrl = response.data.path;
                } else {
                    this.$message.error(response.msg)
                }
            },
            handleError(err, file, fileList) {
                this.$message.error(err)
            },
            handleBefore(file) {
                loadingInstance = elLoading.service({
                    text: 'Desperately uploading',//拼命上传中
                    // spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            uploadNow() {

                if (this.$refs.upload.uploadFiles.length == 0) {
                    this.$message({
                        message: 'Please select a picture first to do this！',//请先选择图片，在进行该操作
                        type: 'warning'
                    });
                } else {
                    if (this.$refs.upload.uploadFiles[0].status == "ready") {
                        this.$refs.upload.submit();
                    } else {
                        this.$message.warning("The picture has been uploaded！")//该图片已经上传
                    }
                }
            },
            //公司模糊查询
            querySearchAsync(queryString, cb) {
                let results = [];
                findCompanyList({
                    companyName: queryString,
                    pageNum: 1,
                    pageSize: 10,
                }).then((res) => {
                    res.data.forEach((e) => {
                        let object = {
                            id: e.company_id,
                            value: e.company_name,
                            logo: e.company_logo,
                            address: e.address,
                            regions: e.regions,
                        };
                        results.push(object);
                    });
                    cb(results);

                })
            },
            handleSelect(item) {
                console.log(item);
                this.companyState = item;
                console.log(this.companyState);
            },
            //职称模糊查询
            positionSearchAsync(queryString, cb) {
                let results = [];
                findPostFunctionList({
                    functionName: queryString,
                    pageNum: 1,
                    pageSize: 10,
                }).then((res) => {
                    res.data.forEach((e) => {
                        let object = {
                            id: e.function_id,
                            value: e.function_name,
                            grade: e.function_grade,
                            parentId: e.parent_id,
                        };
                        results.push(object);
                    });
                    cb(results);
                })
            },
            positionHandleSelect(item) {
                this.position = item.value;
            },
            //  提交信息
            submitMsg() {
                if (this.requireId === '3' && (this.requireBeginTime === '' || this.requireEndTime === '')) {
                    this.$message({
                        message: 'Time period cannot be empty！',//时间段不能为空
                        type: 'warning'
                    });
                    return;
                }
                if (this.mobileCode == null || this.mobileCode.length == 0) {
                    this.$message({
                        message: 'Country code cannot be empty！',//国家区号不能为空
                        type: 'warning'
                    });
                    return;
                }
                if (this.mobile == null || this.mobile.length == 0) {
                    this.$message({
                        message: 'Mobile phone number cannot be empty！',//手机号不能为空
                        type: 'warning'
                    });
                    return;
                }
                let formData = {
                    token: localStorage.getItem('token'),
                    avatar: this.avatarUrl,
                    name: this.name,
                    gender: this.sexId,
                    mobile: this.mobile,
                    mobileCode: this.mobileCode,
                    whatsappCode: this.whatsappCode,
                    mail: this.mail,
                    whatsApp: this.whatsApp,
                    companyName: this.companyState.value,
                    companyId: this.companyState.id,
                    website: this.website,
                    position: this.position,
                    exchangePriceAudio: this.exchangePriceAudio,
                    exchangePriceVideo: this.exchangePriceVideo,
                    myBlog: this.richContent,
                    privacyRequire: this.requireId,
                    requireBeginTime: this.requireBeginTime,
                    requireEndTime: this.requireEndTime,
                    links: this.linkList,
                    voice: this.audio,
                    enclosure: this.enclosure,
                    enclosureName: this.pdfName,
                }
                this.isLoading = true;
                editTalentInfo(formData).then((res) => {
                    this.isLoading = false;
                    if (res.code == 200) {
                        this.$store.commit("updateAvatar", this.avatarUrl)
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //获取地区
            getArea() {
                selectCountriesCodeInfo().then((res) => {
                    if (res.code == 200) {
                        this.areaList = res.data;
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //获取资料
            getUserMsg() {
                let formData = {
                    token: localStorage.getItem('token')
                }
                this.isLoading = true;
                findTalentInfo(formData).then((res) => {
                    this.isLoading = false;
                    this.avatar = res.data.avatar;
                    this.avatarUrl = res.data.avatar;
                    this.name = res.data.name;
                    this.mobile = res.data.mobile;
                    this.mobileCode = res.data.mobileCode;
                    this.mail = res.data.mail;
                    this.whats_app = res.data.whats_app;
                    this.companyState.value = res.data.company_name;
                    this.companyState.id = res.data.company_id;
                    this.companyState.logo = res.data.company_logo;
                    this.website = res.data.website;
                    this.position = res.data.position;
                    this.exchangePriceAudio = res.data.exchange_price_audio;
                    this.exchangePriceVideo = res.data.exchange_price_video;
                    this.requireId = res.data.privacy_require;
                    this.require_begin_time = res.data.require_begin_time;
                    this.require_end_time = res.data.require_end_time;
                    this.sexId = res.data.gender;
                    this.whatsApp = res.data.whats_app;
                    this.whatsappCode = res.data.whatsappCode;
                    this.audio = res.data.voice;
                    this.enclosure = res.data.enclosure;
                    this.pdfName = res.data.enclosureName;
                    /*if (this.enclosure) this.pdfName = '附件';*/

                    // this.editor.txt.html(res.data.my_blog);
                    this.richContent = res.data.my_blog;
                    res.data.linkList.forEach((e) => {
                        switch (e.link_type) {
                            case "1":
                                //维基百科
                                this.wikiLinkList = {
                                    linkType: '1',
                                    linkTitle: e.link_title,
                                    linkUrl: e.link_url,
                                };
                                break;
                            case "2":
                                //YouTube
                                if (this.youtubeLinkList[0].linkUrl == '') this.youtubeLinkList = [];
                                this.youtubeLinkList.push({
                                    linkType: '2',
                                    linkTitle: e.link_title,
                                    linkUrl: e.link_url,
                                });
                                break;
                            case "3":
                                //news
                                if (this.newsLinkList[0].linkUrl == '') this.newsLinkList = [];
                                this.newsLinkList.push({
                                    linkType: '3',
                                    linkTitle: e.link_title,
                                    linkUrl: e.link_url,
                                });
                                break;
                            default:
                        }
                    });
                })
            },

            //添加链接地址

            addLink(type) {
                let link = {
                    linkType: type,
                    linkTitle: '',
                    linkUrl: '',
                }
                if (type === '2') {
                    if (this.youtubeLinkList.length >= 3) {
                        this.$message("Add up to 3 links！");//最多添加3个链接
                    } else {
                        this.youtubeLinkList.push(link);
                    }
                } else if (type === '3') {
                    if (this.newsLinkList.length >= 3) {
                        this.$message("Add up to 3 links！");//最多添加3个链接
                    } else {
                        this.newsLinkList.push(link);
                    }
                }
            },

            //
            delLink(index, type) {
                switch (type) {
                    case '1':
                        break;
                    case '2':
                        if (this.youtubeLinkList.length <= 1) {
                            this.$message("Keep at least one! (can be left blank)");//最少保留一个！（可置为空）
                        } else {
                            this.youtubeLinkList.splice(index, 1);
                        }
                        break;
                    case '3':
                        if (this.newsLinkList.length <= 1) {
                            this.$message("Keep at least one! (can be left blank)");//最少保留一个！（可置为空）
                        } else {
                            this.newsLinkList.splice(index, 1);
                        }
                        break;
                    default:

                }
                this.linkList.splice(index, 1);
            }

        }
    }
</script>

<style lang="scss" scoped>

    @import "../../assets/css/quillFont.css";


    .redact_box {
        width: 1200px;
    }

    .avatar_box {
        padding-top: 52px;
    }

    .avatar {
        width: 100px;
        height: 100px;
    }

    .upload_avatar {
        position: relative;
        margin-left: 25px;
        padding-top: 10px;
        box-sizing: border-box;
    }

    .upload_avatar .upload_file {
        width: 99px;
        height: 32px;
        text-align: center;
        line-height: 30px;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        display: inline-block;
        *display: inline;
        *zoom: 1;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .upload_file input {
        position: absolute;
        font-size: 100px;
        right: 0;
        top: 0;
        opacity: 0;
        filter: alpha(opacity=0);
        cursor: pointer
    }

    .upload_avatar span {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 32px;
        color: #000000;
        padding-left: 5px;
    }

    .upload_avatar p {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 17px;
        color: #949494;
        margin-top: 15px;
    }

    .upload_avatar .avatar_btn {
        width: 141px;
        height: 40px;
        background: #000000;
        border-radius: 131px;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        font-family: BentonSans;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 20px;
        margin-bottom: 52px;
    }

    .input_item {
        margin-bottom: 52px;

        .box-area {
            margin-top: 24px;

            .upload-demo {
                display: inline-block;
                vertical-align: middle;
                margin-right: 93px;
            }

            audio {
                vertical-align: middle;
            }

            .media-box {
                height: 46px;
                background: #333333;
                border-radius: 131px;
                text-align: center;
                line-height: 46px;
                padding: 0 25px;
                //max-width: 150px;

                font-size: 14px;
                font-family: Microsoft YaHei;
                color: #FFFFFF;

                display: inline-block;
                vertical-align: middle;

                img {
                    cursor: pointer;
                    width: 21px;
                    height: 21px;
                    margin-left: 10px;
                }

            }

        }
    }

    .clearfix1 {
        *zoom: 1;

        &:before, &:after {
            content: " ";
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    .input_item_title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
        width: 695px;
    }

    .input_item_title span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #707070;
        padding-left: 5px;
    }

    .input_box {
        width: 695px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        line-height: 50px;
        padding-left: 26px;
        box-sizing: border-box;
        margin-top: 22px;
        margin-left: 11px;
        position: relative;
    }

    .input_box img {
        margin-right: 5px;
    }

    .input_box input {
        width: 500px;
        background: #FAFAFA;
    }

    .select_box {
        position: absolute;
        top: 50px;
        left: 0;
        width: 695px;
        height: 330px;
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        padding: 18px 29px;
        box-sizing: border-box;
        overflow-y: scroll;
    }

    .select_box li {
        margin-bottom: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
    }

    .sex_box {
        margin-top: 20px;
        padding-left: 40px;
        box-sizing: border-box;
        margin-bottom: 50px;
    }

    .sex_item {
        margin-right: 80px;
        line-height: 16px;
    }

    .sex_item img {
        margin-bottom: 4px;
        margin-right: 5px;
    }

    .input_box_number {
        width: 695px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        line-height: 50px;
        padding-left: 26px;
        box-sizing: border-box;
        margin-left: 11px;
    }

    .input_box_onece {
        width: 695px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        line-height: 50px;
        padding-left: 26px;
        box-sizing: border-box;
        margin-left: 11px;
    }

    .input_box_number span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .input_box_number input {
        margin-left: 53px;
        padding-left: 45px;
        height: 22px;
        background: #FAFAFA;
        border-left: 1px solid #d6d6d6;
        width: 500px;
        box-sizing: border-box;
    }

    .select_one input {
        border: none !important;
        width: 650px;
    }

    .three_price {
        margin-top: 22px;
    }

    .three_price li {
        float: left;
        width: 335px;
        height: 50px;
        line-height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        margin-right: 18px;
        padding: 0 26px;
        box-sizing: border-box;
        margin-bottom: 23px;
    }

    .three_price input {
        border: none !important;
        width: 260px;
        background: #FAFAFA;
    }

    .select_two input {
        border: none !important;
        width: 300px;
        background: #FAFAFA;
    }

    .rich_box > p {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 24px;
        color: #949494;
        margin-bottom: 18px;
    }

    .rich_con {
        width: 720px;
        height: 667px;
        //background: #EEEEEE;
        //border: 1px solid #DDDDDD;
        border-radius: 4px;
        margin-bottom: 54px;
    }

    .del_btn img {
        margin-bottom: 4px;
        cursor: pointer;
    }

    .add_more {
        width: 124px;
        height: 40px;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        text-align: center;
        line-height: 38px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-top: 23px;
        margin-bottom: 41px;
    }

    .require_item {
        margin-left: 26px;
        margin-top: 19px;
    }

    .require_item li {
        margin-bottom: 16px;
    }

    .require_item img {
        margin-bottom: 3px;
    }

    .choose_time {
        width: 300px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        line-height: 50px;
        padding-left: 26px;
    }

    .choose_time_a_line {
        line-height: 50px;
        padding: 0 20px;
    }

    .el-date-editor.el-input {
        width: 250px;
    }

    .el-input__inner {
        background: #FAFAFA;
        border: none;
    }

    .el-icon-date:before {
        content: ''
    }

    .el-input--prefix .el-input__inner {
        padding-left: 0;
    }

    .choose_time img {
        width: 20px;
        margin-right: 26px;
        margin-top: 15px;
    }

    .add_more_time {
        width: 94px;
        height: 40px;
        background: #000000;
        border-radius: 131px;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        font-family: BentonSans;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 5px;
        margin-left: 20px;
    }

    .save_btn {
        width: 162px;
        height: 50px;
        background: linear-gradient(180deg, #000000 0%, #232321 100%);
        border-radius: 131px;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        font-family: BentonSans;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 70px;
        margin-bottom: 84px;
    }

    .talk span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .talk img {
        display: inline-block;
        width: 8px;
        height: 15px;
        margin-left: 108px;
        margin-bottom: 3px;
    }
</style>
